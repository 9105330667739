<template>
  <v-container>
    <v-btn text @click="toRoles">
      <v-icon left>mdi-arrow-left</v-icon>
      ロール一覧に戻る
    </v-btn>
    <v-row>
      <v-col  cols="12" class="text-center">
        <h1 class="display-1 font-weight-bold mb-3">ロール一括登録</h1>
      </v-col>
      <v-col cols="8">
        <div class="text-h6 mb-4 font-weight-bold">ファイル選択</div>
        <v-row>
          <v-file-input
            v-model="file"
            label="ユーザ情報CSV"
            placeholder="ファイルを選択してください"
            outlined
            dense
            accept=".csv"
            :rules="rules"
            truncate-length="42"
          />
          <v-btn
            :color="color"
            class="ml-4"
            @click="importFile"
            :loading="uploading"
            :disabled="!(file)"
            width="160px"
          >
            インポート
            <template v-slot:loader>
              <span>インポート中...</span>
            </template>
          </v-btn>
        </v-row>
      </v-col>
      <v-col cols="12" v-if="finish">
        <div class="text-h6 mb-4 font-weight-bold">結果</div>
        <div class="text-body-1 mb-2">
          <div v-for="s in summary" :key="s.name">
            {{ s.name }}: {{ s.count }}件
          </div>
        </div>
        <div v-if="errors.length" class="mb-3">
          <v-alert
            v-for="e in errors"
            :key="e.user"
            dense
            outlined
            type="error"
          >
            {{ e }}
          </v-alert>
        </div>
      </v-col>
    </v-row>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          インポート中
        </v-card-title>
        <v-card-text>
          しばらくお待ちください。
          <v-progress-circular
            indeterminate
            class="ml-2"
            :size="20"
            :width="2"
          ></v-progress-circular>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from "axios";
export default {
  name: "AdminNPA-2",
  data: () => ({
    file: undefined,
    uploading: false,
    finish: false,
    success: false,
    count: 0,
    summary: {
      failed: 0,
      updated: 0,
      inserted: 0,
      total: 0
    },
    errors: [],
    dialog: false,
    rules: [
      value => !value || value.size < 500000 || 'ファイルサイズのサイズ制限は500KBです。',
    ],
  }),
  computed: {
    color: function() {
      return this.uploading ? 'success' : 'primary'
    }
  },
  methods: {
    async postFile(form) {
      this.dialog = true
      this.finish = false
      this.errors = []
      const accessToken = await this.$auth.getTokenSilently();
      axios
        .post("/api/roles-imports", form, {
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })
        .then(response => {
          this.success = true;
          this.summary = response.data.summary
          this.errors = response.data.errors
          this.file = undefined
        })
        .catch(error => {
          this.success = false;
          if (error.response.status === 400) {
            this.errors = error.response.data.errors
          } else if (error.response.status === 401) {
            this.errors.push(error.response.data.detail)
          } else if (error.response.status === 403) {
            this.errors.push(error.response.data.detail)
          } else if (error.response.status === 413) {
            this.errors.push("データサイズが上限値を超えています。")
          } else if (error.response.status === 415) {
            this.errors = error.response.data.errors
          } else if (error.response.status === 504) {
            this.errors.push("タイムアウトエラー。")
          } else {
            this.errors.push("エラーが発生しました。管理者にお問い合わせください。")
          }
        })
        .finally(() => {
          this.uploading = false
          this.finish = true
          this.dialog = false
        });
    },
    importFile() {
      if (this.file) {
        this.uploading = true;
        let form = new FormData();
        form.append("file", this.file);
        this.postFile(form)
      }
    },
    toRoles() {
      this.$router.push({name:'Roles'})
    },
  }
};
</script>